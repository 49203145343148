@import "./variables";

html {
    font-size: 12px;
}

html, body, #root {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // color: $primary-font-color;
    &.body-modifier {
        background-position: 50%;
        background-size: cover;
        overflow: hidden;
        // background-image: url("https://trello-backgrounds.s3.amazonaws.com/SharedBackground/1537x1920/5f72c67b5e9036f5ec4980298c7a22ff/photo-1584433213328-fad675bc3052.jpg");
        // background-image: url("./../static/images/Gradiant\ Background\ -\ 1.jpeg");
        // background-image: url("./../static/images/NKJDiIh-blue-nature-wallpaper.jpg");
    }
}

.c-Router__global-events-interceptor {
    transition: all 0.5s ease-in-out;
    &.dashboard {
        padding: 1rem;
        .c-Router__app-container {
            border-radius: $dashboard-border-radius;
        }
    }

    &.login, &.landing, &.join {
        // background: url("../static/images/home_page_bg.jpg");
        background-size: cover;
        
        .c-Router__main-content {
            backdrop-filter: blur(8px);
        }
    }
}
  
code {
font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fall-behind {
    z-index: 1;
}

.bg-grey{
    background: lightgrey;
}

.backgroundNone {
    background: none !important;
}

.hoverBackgroundNone:hover {
    background: none !important;
}

.scaleUpAndShadow {
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: scale(1.3);
        box-shadow: 0 0.3125rem 1rem rgba(0,0,0,0.3);
    }
}

.right-0 {
    right: 0 !important;
}

.top-100 {
    top: 100% !important;
}

.svg-stroke-white {
    fill: #d3d3d3;
    stroke: #d3d3d3;
    path {
        fill: #d3d3d3;
        stroke: #d3d3d3;
    }
}

.svg-stroke-theme {
    fill: $primary-font-color-dark;
    stroke: $primary-font-color-dark;
    path {
        fill: $primary-font-color-dark;
        stroke: $primary-font-color-dark;
    }
}

.font-size-10 {
    font-size: 0.625rem !important;
}

.font-size-12 {
    font-size: 0.75rem !important;
}


.glass-panel {
    transition: all 0.3s ease-in-out;
    border-radius: 0.1875rem;
    border: 1px solid #ccc;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    // padding: 0.4rem;
    color: #ccc;
}

.raised-effect {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    &:hover {box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);;}
}

.unset-max-width {
    max-width: unset;
}

.border-5 {
    border-radius: 0.3125rem !important;
}

.border-14rem {
    border-radius: 1.4rem !important;
}

.border-none {
    border: none;
}

// .container {
//     max-width: unset !important;
// }

.sub-super-message {
    font-size: 0.75rem;
    margin-bottom: 1.8rem;
    border: 1px solid green;
    border-radius: 0.3125rem;
    padding: 0.125rem 0.3125rem;
    background: white;
    color: green;
}

.error {
    color: #d33;
    border: 1px solid #d33;
}

.page-header {
    padding: 1rem;
}

.border-default-coned {
    border: 1px solid black !important;
}

.border-default {
    border: 1px solid black;
    border-radius: 0.3125rem;
}

.border-default-light {
    border: 1px solid $default-border-color;
    border-radius: 0.3125rem;
}

.border-small-radius {
    border-radius: 0.375rem !important;
}

.border-radius-top-only {
    border-radius: 0.375rem 0.375rem 0 0;
}

.border-radius-bottom-only {
    border-radius: 0 0 0.375rem 0.375rem;
}

.border-unset {
    border: unset;
}

.background-none {
    background: none!important;
}

.w-70 {
    width: 68% !important;
}

.w-0 {
    width: 0% !important;
}

.loader {
    position: relative;
}

.border-red {
    border: 1px solid red !important;
}

::-webkit-input-placeholder {
    font-style: italic;
    font-size: 12px;
}

::-moz-placeholder {
    font-style: italic;
    font-size: 12px;
}

::-ms-input-placeholder {  
    font-style: italic;
    font-size: 12px; 
}

.loader:before {
    display: block;
    content: "";
    position: absolute;
    background-color: transparent;
    // background-image: url("./../static/images/loader.svg");
    background-size: #{$loader-size};
    background-repeat: no-repeat;
    background-position: center;
    height: $size-max;
    width: $size-max;
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
    z-index: 1050;
}
  
.loader:after {
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    z-index: 1040;
}

.default-notification {
    color: darkblue;
    text-align: center;
    margin: 1rem 3rem;
}

.toggle-resizable {
    resize: both;
    overflow: auto;

    // > div {
    //     width: 100% !important;
    //     height: 100% !important;
    // }
}

.pill {
    background: rgb(233, 194, 21);
    font-size: 0.5rem;
    padding: 0.1rem 0.75rem;
    border-radius: 0.3125rem;
    color: #222;
}

.with-box-shadow {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    &:hover {box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);}
}

.borderRight {
    border-right: 1px solid grey;
}

.borderLeft {
    border-left: 1px solid grey;
}

.border-radius-right-only {
    border-radius: 0 8px 8px 0;
}

.border-radius-left-only {
    border-radius: 8px 0 0 8px;
}
@media (min-width: 576px) {
    .border-radius-right-only-sm {
        border-radius: 0 8px 8px 0;
    }

    .border-radius-left-only-sm {
        border-radius: 8px 0 0 8px;
    }

    .w-sm-unset {
        width: unset !important;
    }

    .w-sm-100 {
        width: 100% !important;
    }

    .h-sm-4 {
        height: 4rem !important;
    }

    .border-sm-right {
        border-right: 1px solid grey !important;
        border-bottom: unset !important;
    }

    html {
        font-size: 14px;
    }    
}

.border-bottom {
    border-bottom: 1px solid grey;
}

.mw-0 {
    max-width: 0;
}

.mw-unset {
    max-width: unset;
}

.cursor-pointer {
    cursor: pointer;
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-3 {
    z-index: 3;
}

.z-index-4 {
    z-index: 4;
}

.visibility-hidden {
    visibility: hidden;
}

.top-1 {
    top: 100%;
}

.top-2 {
    top: 200%;
}

.top-3 {
    top: 300%;
}

.list-style-none {
    list-style: none;
}


////////////// TODO keep this class to convert search field to chat box

// .test {
//     background: grey;
//     width: 25rem;
//     position: absolute !important;
//     bottom: 0;
//     right: 26rem;
//     z-index: 1;
//     height: 22rem;

//     input {
//         margin-top: 81%;
//     }
// }

.max-tablet {
    max-width: 654px;
}

.l-0 {
    left: 0 !important;
}

.r-0 {
    right: 0 !important;
}

.arm-pt-4 {
    padding-top: 4rem;
}

.arm-mt-6 {
    margin-top: 6rem;
}

.z-1 {
    z-index: 1;
}

.z-2 {
    z-index: 2;
}

.z-3 {
    z-index: 3;
}

.z-4 {
    z-index: 4;
}

.z-n1 {
    z-index: -1;
}

// Tailwind adapter
.transition { transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform; }
.duration-1000 { transition-duration: 1000ms; }
.duration-500 { transition-duration: 500ms; }
.duration-300 { transition-duration: 300ms; }
.blur-none { filter: blur(0); }
.blur-lg { 	filter: blur(16px); }
.translate-x-0 { transform: translateX(0px); }
.-translate-x-20 { transform: translateX(-20px); }
///////

@media (min-width: 576px) {
    .extend-row {
        margin-left: -4.2rem;
        // margin-right: -2.5rem;
    }
}